
import Breadcrumb from /* webpackChunkName: "Breadcrumb" */ '../../../components/Breadcrumb'
import NavigationWeb from /* webpackChunkName: "NavigationWeb" */ './NavigationWeb'
export default {
  name: 'FcNavigation',
  components: {
    NavigationWeb,
    NavigationResponsive: () => import(/* webpackChunkName: "NavigationResponsive" */ './NavigationResponsive'),
    Breadcrumb
  },
  data: () => ({
    navigationComponent: null,
    scrollingDown: false
  }),
  computed: {
    showBreadcrumb () {
      return !(['pricing', 'auth', 'checkout', 'index'].some((v) => {
        return this.$route.name ? this.$route.name.includes(v) : false
      })) && !this.dashboard
    },
    dashboard () {
      return this.$route.fullPath ? this.$route.fullPath.includes('account') : null
    },
    isTab () { return this.$store.state.store.isTab },
    isMobile () { return this.$store.state.store.isMobile }
  }
}
